<template>
    <div class="div"><h1>Gérer les Travailleurs Occasionnels</h1></div>
</template>
<script>
export default {
    setup() {
        
    },
    data:{
        
    }
}
</script>